import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch, InputNumber } from "antd";

import {
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useTranslate,
} from "@refinedev/core";

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IUserForUpdate } from "../../../entities/visa/IUserForUpdate";
import { useState } from "react";
import { ConvertStatusID } from "helpers/statusHelper";
import React from "react";
import { IRoleResponse } from "entities/visa/IRoleResponse";
import { css } from 'aphrodite'
import sheet from './style';
import IIncidentCategory from "entities/definition/IIncidentCategory";
import { IUserForView } from "entities/visa/IUserForView";
import { useConstantValueFromService } from "hooks";

declare type ActionParams = {
    action?: "edit" | "create" | "clone";
};

export const IncidentCauseEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IUserForUpdate>({ redirect: false, action: parsedAction == "edit" ? "edit" : "create", mutationMode: "undoable" });
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 1;
    const { goBack, list } = useNavigation();


    if (mutationResult.isSuccess) {
        redirect("list");
    }

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    const { selectProps } = useSelect<IIncidentCategory>({
        resource: "IncidentCategory",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: userSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
       optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                 field: "Username",
                operator: "contains",
                value
        }
        ],
        pagination: {
            mode: "server"
     }
     });

    // const { selectProps:periodSelectProps } = useConstantValueFromService("GetPeriodTypes");

    return (
        <Edit
            isLoading={formLoading}
            headerButtons={({ defaultButtons }) => (
                <>
                    <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                        children={translate("pages.incidentcause.title")}
                    />
                    {parsedAction == "edit" && (
                        <RefreshButton
                            resource={parsedResource?.name}
                            recordItemId={parsedId}
                        />
                    )}
                </>
            )}
            footerButtons={({ defaultButtons }) => (
                <>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    {CheckPermissionsV2("Delete", "Business.Concrete.Definition.IncidentCauseManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </>
            )}
            title={translate("buttons.edit")}
            recordItemId={parsedId}
            canDelete={CheckPermissionsV2("Delete", "Business.Concrete.Definition.IncidentCauseManager")}>

            <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>

                    <Col span={12} key="incidentCategoryID">
                        <Form.Item label={translate("pages.incidentcause.fields.incidentcategory")} name="incidentCategoryID">
                            <Select {...selectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="incidentAssignedUserID">
                        <Form.Item label={translate("pages.incidentcause.fields.responsibleUser")} name="incidentAssignedUserID">
                            <Select {...userSelectProps} />
                        </Form.Item>
                    </Col>
                    {/* <Col span={12} key="periodTypeID">
                        <Form.Item label={translate("pages.incidentcause.fields.periodType")} name="periodTypeID">
                            <Select {...periodSelectProps} />
                        </Form.Item>
                    </Col> */}
                    {/* <Col span={12} key="period">
                        <Form.Item label={translate("pages.incidentcause.fields.period")} name="period">
                            <InputNumber />
                        </Form.Item>
                    </Col> */}
                    <Col span={12} key="Name">
                        <Form.Item label={translate("pages.incidentcause.fields.name")} name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12} key="statusID">
                        <Form.Item label={translate("general.status.title")} name="statusID" initialValue={initialStatus}>
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Edit >
    );
};
