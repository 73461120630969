import { Refine, Authenticated, CanAccess } from "@refinedev/core";
import { notificationProvider, ThemedLayoutV2 } from "@refinedev/antd";
import { theme, ConfigProvider } from "antd";
import routerProvider, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { LoginPage } from "pages/auth/login";
import axios from "axios";
import { useState } from "react";

import { RefineKbarProvider, RefineKbar } from "@refinedev/kbar";

import { authProvider, axiosInstance } from "providers/authProvider";
import { JsonServer } from "providers/dataProvider";

import { PerformanceDashboardPage } from "pages/performancedashboard";
import { ForgetPasswordPage } from "pages/auth/forgetpassword";
import { GetServiceUrl } from "helpers/configManager";


import { Header, CustomSider, ApplicationTitle } from "components/layout";
import { OffLayoutArea } from "components/offLayoutArea";



import tr_TR from 'antd/locale/tr_TR';
import en_US from 'antd/locale/en_US';

import 'dayjs/locale/en';
import 'dayjs/locale/tr';
import dayjs from "dayjs";

import "@refinedev/antd/dist/reset.css";
import "styles/antd.css";



//resources
import { EntityLogList, EntityLogShow } from "pages/configuration/entitylog";
import { ErrorLogList, ErrorLogShow } from "pages/configuration/errorlog";
import { RequestLogList, RequestLogShow } from "pages/configuration/requestlog";
import { TranslationList, TranslationEdit } from "pages/configuration/translation";
import { ClientRoleList, ClientRoleEdit } from "pages/visa/clientrole";
import { ForbiddenUsernameList, ForbiddenUsernameEdit } from "pages/visa/forbiddenUsername";
import { PermissionList, PermissonEdit } from "pages/visa/permission";
import { RoleList, RoleEdit } from "pages/visa/role";
import { UserList, UserEdit } from "pages/visa/user";
import { ParameterList, ParameterEdit } from "pages/configuration/parameter";
import { MailTemplateList, MailTemplateEdit } from "pages/configuration/mailtemplate";
import { MailTaskList } from "pages/configuration/mailtask";
import { MobileAppNotificationLogList } from "pages/configuration/mobileappnotificationlog";
import { IncidentCategoryList, IncidentCategoryEdit } from "pages/definition/incidentcategory";
import { IncidentCauseList, IncidentCauseEdit } from "pages/definition/incidentcause";
import { AuthorizedIncidentOfficerEdit, AuthorizedIncidentOfficerList } from "pages/definition/authorizedincidentofficer";
import { IncidentStandardDistributionListEdit, IncidentStandardDistributionListList } from "pages/definition/IncidentStandardDistributionList";
import { DistrictEdit, DistrictList } from "pages/definition/District";
import { DepartmentList, DepartmentEdit } from "pages/definition/Department";
import { StoreList, StoreEdit } from "pages/definition/Store";
import { AssignedIncidentsList, AuthorizedIncidentList, IncidentEdit, IncidentList, MyIncidentList, IncidentShow } from "pages/event/Incident";
import { NotificationList } from "pages/configuration/notification";
import { ErrorComponent } from "components/page/error";
import { MailAccountList, MailAccountEdit } from "pages/configuration/mailaccount";
import { RenewForgottenPasswordPage } from "pages/auth/renewforgottenpassword";
import { DashboardPage } from "pages/dashboard";

function changeGlobalDateFormat(lang: string) {

  try {
    dayjs.locale(lang);
  }
  catch (e) {
    console.error(e);
  }
}

function App() {
  var initialTheme: "light" | "dark" = "light";
  var localStorageTheme = localStorage.getItem("theme");

  if (localStorageTheme === "dark") {
    initialTheme = localStorageTheme;
  }

  localStorage.setItem("theme", initialTheme);

  const [currentTheme, setCurrentTheme] = useState<"light" | "dark">(initialTheme);

  const ChangeCurrentTheme = (theme: "light" | "dark") => {
    localStorage.setItem("theme", theme);
    setCurrentTheme(theme);
  }

  axios.defaults.baseURL = GetServiceUrl();

  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => {
      if (lang == "tr-TR") {
        changeGlobalDateFormat("tr");
      }
      else {
        changeGlobalDateFormat("en");
      }
      return i18n.changeLanguage(lang);
    },
    getLocale: () => i18n.language,
  };

  if (i18nProvider.getLocale() == "tr-TR") {
    changeGlobalDateFormat("tr");
  }
  else {
    changeGlobalDateFormat("en");
  }

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          algorithm:
            currentTheme === "light"
              ? theme.defaultAlgorithm
              : theme.darkAlgorithm
        }}
        locale={i18n.language == "tr-TR" ? tr_TR : en_US}
      >
        <RefineKbarProvider>
          <Refine
            dataProvider={JsonServer(GetServiceUrl(), axiosInstance)}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              disableTelemetry: true,
              reactQuery: {
                clientConfig: {
                  defaultOptions: {
                    queries: {
                      refetchOnWindowFocus: true,
                    }
                  }
                }
              }
            }}
            notificationProvider={notificationProvider}
            routerProvider={routerProvider}
            resources={[
              {
                name: "performancedashboard",
                list: "/performancedashboard",
                meta: {
                  label: i18n.t("menu.dashboard.performance"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.RequestLogManager",
                      Method: "CalculateApdexScore"
                    },
                    {
                      Controller: "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetErrorPercentage"
                    },
                    {
                      Controller: "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetActiveRequestCount"
                    },
                    {
                      Controller: "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetCpuUsage"
                    },
                    {
                      Controller: "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetRequestInfoPerTimePerEndpoint"
                    },
                    {
                      Controller: "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetRequestCountPerTime"
                    },
                    {
                      Controller: "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetDataOutPerTime"
                    }
                  ]
                },
              },
              {
                name: "dashboard",
                list: "/dashboard",
                meta: {
                  label: i18n.t("menu.dashboard.dashboard"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Event.IncidentManager",
                      Method: "GetMyIncidents_MENU"
                    }
                  ]
                },
              },
              {
                name: "User",
                meta: {
                  label: i18n.t("menu.visa.user"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.UserManager",
                      Method: "GetUserList_MENU"
                    }
                  ]
                },
                list: "/visa/users",
                edit: "/visa/users/:id/edit",
                create: "/visa/users/create"
              },
              {
                name: "Permission",
                meta: {
                  label: i18n.t("menu.visa.permission"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.PermissionManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/visa/permissions",
                edit: "/visa/permissions/:id/edit",
              },
              {
                name: "Role",
                meta: {
                  label: i18n.t("menu.visa.role"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.RoleManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/visa/roles",
                edit: "/visa/roles/:id/edit",
                create: "/visa/roles/create",
              },
              {
                name: "ClientPermission",
                meta: {
                  label: i18n.t("menu.visa.clientpermission"),
                  parent: i18n.t("menu.visa.title")
                },
              },
              {
                name: "ClientRole",
                meta: {
                  label: i18n.t("menu.visa.clientrole"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.ClientRoleManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/visa/clientroles",
                edit: "/visa/clientroles/:id/edit",
                create: "/visa/clientroles/create",
              },
              {
                name: "ForbiddenUsername",
                meta: {
                  label: i18n.t("menu.visa.forbiddenUsername"),
                  parent: i18n.t("menu.visa.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Visa.ForbiddenUsernameManager",
                      Method: "GetList_MENU"
                    }
                  ]

                },
                list: "/visa/forbiddenusernames",
                edit: "/visa/forbiddenusernames/:id/edit",
                create: "/visa/forbiddenusernames/create",
              },
              {
                name: "Translation",
                meta: {
                  label: i18n.t("menu.configuration.translation"),
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.TranslationManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/configuration/translations",
                edit: "/configuration/translations/:id/edit",
                create: "/configuration/translations/create",
              },
              {
                name: "Parameter",
                meta: {
                  label: i18n.t("menu.configuration.parameter"),
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.ParameterManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/configuration/parameters",
                edit: "/configuration/parameters/:id/edit",
                create: "/configuration/parameters/create",
              },
              {
                name: "MailTemplate",
                meta: {
                  label: i18n.t("menu.configuration.mailtemplate"),
                  theme: currentTheme,
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.MailTemplateManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/configuration/mailtemplates",
                edit: "/configuration/mailtemplates/:id/edit",
                create: "/configuration/mailtemplates/create",
              },
              {
                name: "MailTask",
                meta: {
                  label: i18n.t("menu.configuration.mailtask"),
                  theme: currentTheme,
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.MailTaskManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/configuration/mailtasks",
              },
              {
                name: "MailAccount",
                meta: {
                  label: i18n.t("menu.configuration.mailaccount"),
                  theme: currentTheme,
                  parent: i18n.t("menu.configuration.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.MailAccountManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/configuration/mailaccounts",
                edit: "/configuration/mailaccounts/:id/edit",
              },
              {
                name: "Log",
                meta: {
                  label: i18n.t("menu.log.errorlog"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.LogManager",
                      Method: "GetLogs_MENU"
                    }
                  ]
                },
                list: "/log/errorlogs",
                show: "/log/errorlogs/:id/show",
              },
              {
                parentName: "Logs",
                name: "RequestLog",
                meta: {
                  label: i18n.t("menu.log.requestlog"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.RequestLogManager",
                      Method: "GetLogs_MENU"
                    }
                  ]
                },
                list: "/log/requestlogs",
                show: "/log/requestlogs/:id/show",
              },
              {
                name: "EntityLog",
                meta: {
                  label: i18n.t("menu.log.auditLog"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.EntityLogManager",
                      Method: "GetLogs_MENU"
                    }
                  ]

                },
                list: "/log/entitylogs",
                show: "/log/entitylogs/:id/show",
              },
              {
                name: "MobileAppNotificationLog",
                meta: {
                  label: i18n.t("menu.log.mobileAppNotificationLog"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.MobileAppNotificationLogManager",
                      Method: "GetLogs_MENU"
                    }
                  ]
                },
                list: "/log/mobileappnotificationlogs",
              },
              {
                name: "Notification",
                meta: {
                  label: i18n.t("menu.log.Notification"),
                  parent: i18n.t("menu.log.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Configuration.NotificationManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/log/notifications",
              },
              {
                name: "AuthorizedIncidentOfficer",
                meta: {
                  label: i18n.t("menu.definition.authorizedincidentofficer"),
                  parent: i18n.t("menu.definition.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Definition.AuthorizedIncidentOfficer",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/definition/authorizedincidentofficers",
                edit: "/definition/authorizedincidentofficers/:id/edit",
                create: "/definition/authorizedincidentofficers/create"
              },
              {
                name: "IncidentCategory",
                meta: {
                  label: i18n.t("menu.definition.incidentcategory"),
                  parent: i18n.t("menu.definition.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Definition.IncidentCategoryManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/definition/incidentcategories",
                edit: "/definition/incidentcategories/:id/edit",
                create: "/definition/incidentcategories/create"
              },
              {
                name: "IncidentCause",
                meta: {
                  label: i18n.t("menu.definition.incidentcause"),
                  parent: i18n.t("menu.definition.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Definition.IncidentCauseManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/definition/incidentcauses",
                edit: "/definition/incidentcauses/:id/edit",
                create: "/definition/incidentcauses/create"
              },
              {
                name: "IncidentStandardDistributionList",
                meta: {
                  label: i18n.t("menu.definition.IncidentStandardDistributionList"),
                  parent: i18n.t("menu.definition.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Definition.IncidentStandardDistributionListManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/definition/IncidentStandardDistributionLists",
                edit: "/definition/IncidentStandardDistributionLists/:id/edit",
                create: "/definition/IncidentStandardDistributionLists/create"
              },
              {
                name: "District",
                meta: {
                  label: i18n.t("menu.definition.District"),
                  parent: i18n.t("menu.definition.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Definition.DistrictManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/definition/Districts",
                edit: "/definition/Districts/:id/edit",
                create: "/definition/Districts/create"
              },
              {
                name: "Department",
                meta: {
                  label: i18n.t("menu.definition.Department"),
                  parent: i18n.t("menu.definition.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Definition.DepartmentManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/definition/Departments",
                edit: "/definition/Departments/:id/edit",
                create: "/definition/Departments/create"
              },
              {
                name: "Store",
                meta: {
                  label: i18n.t("menu.definition.Store"),
                  parent: i18n.t("menu.definition.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Definition.StoreManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/definition/Stores",
                edit: "/definition/Stores/:id/edit",
                create: "/definition/Stores/create"
              },
              {
                name: "Incident",
                meta: {
                  label: i18n.t("menu.event.Incident"),
                  parent: i18n.t("menu.event.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Event.IncidentManager",
                      Method: "GetList_MENU"
                    }
                  ]
                },
                list: "/event/Incidents/all",
                edit: "/event/Incidents/:id/edit",
                create: "/event/Incidents/create",
                show: "/event/Incidents/:id/show",
                identifier: "Incident"
              },
              {
                name: "Incident",
                meta: {
                  label: i18n.t("menu.event.MyIncident"),
                  parent: i18n.t("menu.event.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Event.IncidentManager",
                      Method: "GetMyIncidents_MENU"
                    }
                  ]
                },
                list: "/event/Incidents/myincident",
                show: "/event/Incidents/:id/show",
                edit: "/event/Incidents/:id/edit",
                create: "/event/Incidents/create",
                identifier: "MyIncident"
              },    
              {
                name: "Incident",
                meta: {
                  label: i18n.t("menu.event.Authorizedincident"),
                  parent: i18n.t("menu.event.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Event.IncidentManager",
                      Method: "GetAuthorizedIncidents_MENU"
                    }
                  ]
                },
                list: "/event/Incidents/authorizedincident",
                show: "/event/Incidents/:id/show",
                edit: "/event/Incidents/:id/edit",
                create: "/event/Incidents/create",
                identifier: "Authorizedincident"
              },           
              {
                name: "Incident",
                meta: {
                  label: i18n.t("menu.event.AssignedIncident"),
                  parent: i18n.t("menu.event.title"),
                  permissions: [
                    {
                      Controller: "Business.Concrete.Event.IncidentManager",
                      Method: "GetAssignedIncidents_MENU"
                    }
                  ]
                },
                list: "/event/Incidents/assigned",
                show: "/event/Incidents/:id/show",
                edit: "/event/Incidents/:id/edit",
                identifier: "AssignedIncident"
              }
            ]}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <Navigate to="/dashboard" />
                  </Authenticated>
                }
              >
                <Route
                  path="/login"
                  element={
                    <LoginPage
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                  }
                />
                <Route
                  path="/forget-password"
                  element={<ForgetPasswordPage theme={currentTheme} setTheme={ChangeCurrentTheme} />}
                />

                <Route
                  path="/renewpass/:token"
                  element={<RenewForgottenPasswordPage theme={currentTheme} setTheme={ChangeCurrentTheme} />}
                />
              </Route>

              <Route
                element={
                  <Authenticated
                    redirectOnFail="/login"
                  >
                    <ThemedLayoutV2
                      Sider={() => (<CustomSider Title={({ collapsed }) => (<ApplicationTitle collapsed={collapsed} theme={currentTheme} />)} />)}
                      Header={() => (<Header setTheme={setCurrentTheme} theme={currentTheme} />)}
                      OffLayoutArea={OffLayoutArea}
                      Title={({ collapsed }) => (<ApplicationTitle collapsed={collapsed} theme={currentTheme} />)}
                    >
                      <CanAccess fallback={<ErrorComponent status="403" />} >
                        <Outlet />
                      </CanAccess>
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<MyIncidentList />} />

                <Route path="/performancedashboard">
                  <Route index element={<PerformanceDashboardPage />} />
                </Route>

                <Route path="/dashboard">
                  <Route index element={<DashboardPage />} />
                </Route>

                <Route path="/visa/users">
                  <Route index element={<UserList />} />
                  <Route
                    path=":id/edit"
                    element={<UserEdit />}
                  />
                  <Route
                    path="create"
                    element={<UserEdit />}
                  />
                </Route>

                <Route path="/visa/permissions">
                  <Route index element={<PermissionList />} />
                  <Route
                    path=":id/edit"
                    element={<PermissonEdit />}
                  />
                  <Route
                    path="create"
                    element={<PermissonEdit />}
                  />
                </Route>

                <Route path="/visa/roles">
                  <Route index element={<RoleList />} />
                  <Route
                    path=":id/edit"
                    element={<RoleEdit />}
                  />
                  <Route
                    path="create"
                    element={<RoleEdit />}
                  />
                </Route>

                <Route path="/visa/clientroles">
                  <Route index element={<ClientRoleList />} />
                  <Route
                    path=":id/edit"
                    element={<ClientRoleEdit />}
                  />
                  <Route
                    path="create"
                    element={<ClientRoleEdit />}
                  />
                </Route>

                <Route path="/visa/forbiddenusernames">
                  <Route index element={<ForbiddenUsernameList />} />
                  <Route
                    path=":id/edit"
                    element={<ForbiddenUsernameEdit />}
                  />
                  <Route
                    path="create"
                    element={<ForbiddenUsernameEdit />}
                  />
                </Route>

                <Route path="/configuration/translations">
                  <Route index element={<TranslationList />} />
                  <Route
                    path=":id/edit"
                    element={<TranslationEdit />}
                  />
                  <Route
                    path="create"
                    element={<TranslationEdit />}
                  />
                </Route>

                <Route path="/configuration/parameters">
                  <Route index element={<ParameterList />} />
                  <Route
                    path=":id/edit"
                    element={<ParameterEdit />}
                  />
                  <Route
                    path="create"
                    element={<ParameterEdit />}
                  />
                </Route>

                <Route path="/configuration/mailtemplates">
                  <Route index element={<MailTemplateList />} />
                  <Route
                    path=":id/edit"
                    element={<MailTemplateEdit />}
                  />
                  <Route
                    path="create"
                    element={<MailTemplateEdit />}
                  />
                </Route>

                <Route path="/configuration/mailtasks">
                  <Route index element={<MailTaskList />} />
                </Route>

                <Route path="/configuration/mailaccounts">
                  <Route index element={<MailAccountList />} />
                  <Route
                    path=":id/edit"
                    element={<MailAccountEdit />}
                  />
                </Route>



                <Route path="/log/notifications">
                  <Route index element={<NotificationList />} />

                </Route>

                <Route path="/log/errorlogs">
                  <Route index element={<ErrorLogList />} />
                  <Route
                    path=":id/show"
                    element={<ErrorLogShow />}
                  />
                </Route>


                <Route path="/log/requestlogs">
                  <Route index element={<RequestLogList />} />
                  <Route
                    path=":id/show"
                    element={<RequestLogShow />}
                  />
                </Route>

                <Route path="/log/entitylogs">
                  <Route index element={<EntityLogList />} />
                  <Route
                    path=":id/show"
                    element={<EntityLogShow />}
                  />
                </Route>

                <Route path="/log/mobileappnotificationlogs">
                  <Route index element={<MobileAppNotificationLogList />} />
                </Route>

                <Route path="/definition/authorizedincidentofficers">
                  <Route index element={<AuthorizedIncidentOfficerList />} />
                  <Route path=":id/edit" element={<AuthorizedIncidentOfficerEdit />} />
                  <Route path="create" element={<AuthorizedIncidentOfficerEdit />} />
                </Route>


                <Route path="/definition/incidentcategories">
                  <Route index element={<IncidentCategoryList />} />
                  <Route path=":id/edit" element={<IncidentCategoryEdit />} />
                  <Route path="create" element={<IncidentCategoryEdit />} />
                </Route>

                <Route path="/definition/incidentcauses">
                  <Route index element={<IncidentCauseList />} />
                  <Route path=":id/edit" element={<IncidentCauseEdit />} />
                  <Route path="create" element={<IncidentCauseEdit />} />
                </Route>

                <Route path="/definition/authorizedincidentofficers">
                  <Route index element={<AuthorizedIncidentOfficerList />} />
                  <Route path=":id/edit" element={<AuthorizedIncidentOfficerEdit />} />
                  <Route path="create" element={<AuthorizedIncidentOfficerEdit />} />
                </Route>

                <Route path="/definition/IncidentStandardDistributionLists">
                  <Route index element={<IncidentStandardDistributionListList />} />
                  <Route path=":id/edit" element={<IncidentStandardDistributionListEdit />} />
                  <Route path="create" element={<IncidentStandardDistributionListEdit />} />
                </Route>

                <Route path="/definition/Districts">
                  <Route index element={<DistrictList />} />
                  <Route path=":id/edit" element={<DistrictEdit />} />
                  <Route path="create" element={<DistrictEdit />} />
                </Route>

                <Route path="/definition/Departments">
                  <Route index element={<DepartmentList />} />
                  <Route path=":id/edit" element={<DepartmentEdit />} />
                  <Route path="create" element={<DepartmentEdit />} />
                </Route>

                <Route path="/definition/Stores">
                  <Route index element={<StoreList />} />
                  <Route path=":id/edit" element={<StoreEdit />} />
                  <Route path="create" element={<StoreEdit />} />
                </Route>

                <Route path="/event/Incidents">
                  <Route index element={<MyIncidentList />} />
                  <Route path=":id/edit" element={<IncidentEdit />} />
                  <Route path="create" element={<IncidentEdit />} />
                  <Route path="all" element={<IncidentList />} />
                  <Route path="myincident" element={<MyIncidentList />} />
                  <Route path="authorizedincident" element={<AuthorizedIncidentList />} />
                  <Route path="assigned" element={<AssignedIncidentsList />} />
                  <Route path=":id/show" element={<IncidentShow />} />
                </Route>


              </Route>

              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <ThemedLayoutV2
                      Sider={() => (<CustomSider Title={({ collapsed }) => (<ApplicationTitle collapsed={collapsed} theme={currentTheme} />)} />)}
                      Header={() => (<Header setTheme={setCurrentTheme} theme={currentTheme} />)}
                      OffLayoutArea={OffLayoutArea}
                      Title={({ collapsed }) => (<ApplicationTitle collapsed={collapsed} theme={currentTheme} />)}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="forbidden" element={<ErrorComponent status="403" />} />
                <Route path="*" element={<ErrorComponent status="404" />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
          </Refine>
        </RefineKbarProvider>
      </ConfigProvider>
    </BrowserRouter >
  );
}

export default App;
