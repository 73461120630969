import {  MyIncidentList } from "pages/event/Incident"

export const DashboardPage: React.FC = () => {

    return(
        <>
        <MyIncidentList/>
        
        </>
    )
}