import { List, TextField, TagField, useTable, ShowButton, EditButton, useSelect } from "@refinedev/antd";
import { Table, Row, Col, Card } from "antd";
import { IResourceComponentsProps, useShow, useTranslate } from "@refinedev/core";
import { IUserForView } from "../../../entities/visa/IUserForView";
import { usePermissions } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useState } from "react";
import { Breadcrumb } from "components/breadcrumb";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { DateField } from "components/fields/date";
import IIncidentCategory from "entities/definition/IIncidentCategory";

export const IncidentCauseList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult, setFilters, setSorters: setSorter } = useTable<IUserForView>();
    const translate = useTranslate();

    const { selectProps } = useSelect<IIncidentCategory>({
        resource: "IncidentCategory",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps:userSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Username",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.incidentcause.fields.id"),
            type: "number"
        },
        {
            key: "Name",
            title: translate("pages.incidentcause.fields.name"),
            type: "string"
        },
        {
            key: "IncidentCategoryID",
            title: translate("pages.incidentcause.fields.incidentcategory"),
            type: "number",
            selectProps: selectProps
        },
        {
            key: "IncidentAssignedUserID",
            title: translate("pages.incidentcause.fields.responsibleUser"),
            type: "number",
            selectProps: userSelectProps
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.incidentcause.fields.id")
        },
        {
            dataIndex: ["incidentCategory","name"],
            title: translate("pages.incidentcause.fields.incidentcategory")
        },
        {
            dataIndex: "name",
            title: translate("pages.incidentcause.fields.name")
        },
        {
            dataIndex: ["incidentAssignedUserID","username"],
            title: translate("pages.incidentcause.fields.responsibleUser")
        },
        {
            dataIndex: ["periodType","name"],
            title: translate("pages.incidentcause.fields.periodType")
        },
        {
            dataIndex: "period",
            title: translate("pages.incidentcause.fields.period")
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <EditButton
                    size="small"
                    recordItemId={props.record.id}
                    value={translate("general.button.show")}
                />
            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.incidentcause.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Definition.IncidentCauseManager"),
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={tableQueryResult.isFetching} />
    );
};